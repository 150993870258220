import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import menuLine from "../Assets/menu-line.svg"
import close from "../Assets/add.svg"
import instagram from "../Assets/instagram-fill.svg"
import twitter from "../Assets/twitter-fill.svg"

const Style = styled.div`
    min-height: 100vh;
    width: 100vw;
    z-index: 100;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding-top: 60px;
    .content {
        flex-grow: 1;
    }
    .navbar {
        width: calc(100% - 280px);
        z-index: 999;
        box-shadow: 0px 2px 8px #0000001f;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 140px;
        height: 60px;
        position: fixed;
        inset: 0 0 auto 0;
        background: var(--nav-bg);
        backdrop-filter: blur(30px);
        .logo {
            padding: 0.5rem 0;
            img {
                height: 100%;
            }
        }
        .menu-btn {
            display: none;
        }
        .items {
            display: flex;
            .navitem {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 1rem;
                text-decoration: none;
                color: var(--fsc-d-1);
                font-size: 14px;
                &.active {
                    font-weight: 700;
                }
            }
        }
    }
    .footer {
        background-color: var(--bg-d-2);
        color: var(--text-d-1);
        padding: 60px 140px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        .footer-back {
            z-index: 0;
            position: absolute;
            inset: auto 0 0 0;
            img {
                width: 100%;
                display: block;
            }
        }
        .container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            &.right {
                align-content: flex-start;
            }
            &.left {
                align-content: flex-end;
            }
            .logo {
                margin: 0 0 1rem 0;
                img {
                    height: 85px;
                }
            }
            .text {
                font-size: 24px;
            }
            .text-s {
                font-size: 12px;
            }
            .socials {
                display: flex;
                flex-direction: row;
                margin: 0.5rem 0 0;
                .item {
                    margin: 0 0.5rem;
                    :last-child{
                        margin: 0 0.5rem 0 0;
                    }
                    :first-child{
                        margin: 0 0 0 0.5rem;
                    }
                }
            }
        }
        
    }
    .down{
        padding: 0.5rem 0;
        text-align: center;
        a {
            color: var(--text-d-1);
            text-decoration: none;
            :hover {
                color: var(--n-grey-1)
            }
        }
    }
    @media (max-width: 1000px){
        .navbar {
            justify-content: center;
            .items {
                display: none;
            }
            .menu-btn {
                z-index: 980;
                overflow: visible;
                cursor: pointer;
                position: fixed;
                top: 10px;
                right: 16px;
                width: 40px;
                height: 40px;
                background-color: var(--bg-d-2);
                display: flex;
                justify-content: center;
                border-radius: 4px;
                border: 1px solid var(--bg-d-3);
                .menu-btn-img {
                    width: 20px;
                    height: 20px;
                    margin: auto;
                    transition: all 0.3s ease-in-out;
                }
                .bg {
                    z-index: 950;
                    position: fixed;
                    inset: 0 0 0 0;
                    width: 100vw;
                    height: 100vh;
                    background-color: #0000005f;
                }
                .menu-container {
                    z-index: 970;
                    display: flex;
                    flex-direction: column;
                    position: fixed;
                    background-color: var(--bg-d-2);
                    width: 80vw;
                    height: 100vh;
                    inset: 0 0 0 auto;
                    right: -80vw;
                    transition: all 0.3s ease-in-out;
                    .logo {
                        width: 80%;
                        margin: 70px auto 35px;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .menu-items {
                        display: flex;
                        flex-direction: column;
                        .navitem {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding: 1rem;
                            text-decoration: none;
                            color: var(--fsc-d-1);
                            font-size: 16px;
                            border-bottom: 1px solid var(--bg-d-3);
                            &.active {
                                background-color: var(--bg-d-3);
                                font-weight: 700;
                            }
                        }
                    }
                    .text-s {
                        margin: 0.5rem auto;
                    }
                    .socials {
                        margin: 0.5rem auto 5rem;
                        display: flex;
                        flex-direction: row;
                        .item {
                            margin: 0 0.5rem;
                            :last-child{
                                margin: 0 0.5rem 0 0;
                            }
                            :first-child{
                                margin: 0 0 0 0.5rem;
                            }
                        }
                    }
                }
            }
        }
        .footer {
            padding: 60px 16px;
            .container {
                .logo {
                    margin: 0 0 1rem 0;
                    img {
                        height: 60px;
                    }
                }
                .text {
                    font-size: 18px;
                }
            }
        }
    }
`;

const navbarItems = [
    {
        title: 'صفحه‌ی اصلی',
        link: '/'
    },
    {
        title: 'درباره ما',
        link: '/aboutus'
    },
]



export default function PageLayout(props) {
    const location = useLocation();
    const [open, setOpen] = useState(false)
    const makeNavItems = () => {
        return navbarItems.map((value, index) => {
            return (
                <Link to={value.link} className={"navitem" + ((location.pathname === value.link) ? ' active' : '')} key={index}>
                    {value.title}
                </Link>
            )
        })
    }
    const openMenu = () => {
        console.log(open);
        setOpen(!open)
    }
    return (
        <Style>
            <div className="navbar">
                <Link to="/" className="logo">
                    {/* <img src={logodark} alt="only voice remains"/> */}
                </Link>
                <div className="items">
                    {makeNavItems()}
                </div>
                <div className="menu-btn">
                    <img src={menuLine} onClick={openMenu} className="menu-btn-img" alt="menu"/>
                    <div className="bg" onClick={openMenu} style={{display: ((open) ? 'block': 'none')}}></div>
                    <div className="menu-container" style={{right: ((open) ? '0vw': '-80vw')}}>
                        <div className="menu-btn">
                            <img src={(open ? close : menuLine)} onClick={openMenu} style={{transform: `rotate(${open ? '45' : '0'}deg)`}} className="menu-btn-img" alt="menu"/>
                        </div>
                        <Link to="/" className="logo">
                            {/* <img src={logodark} alt="only voice remains"/> */}
                        </Link>
                        <div className="menu-items">
                            {makeNavItems()}
                        </div>
                        <div style={{flexGrow: 1}}></div>
                        <div className="text-s">
                            شبکه های اجتماعی
                        </div>
                        <div className="socials">
                            <a href="#footer" target={"_blank"} className="item">
                                <img src={instagram} alt="podday instagram"/>
                            </a>
                            <a href="#footer" target={"_blank"} className="item">
                                <img src={twitter} alt="podday instagram"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                {props.children}
            </div>
            <div className="footer">
                
            </div>
            <div className="down">
                تهیه و توسعه توسط عرفان کریمیان
            </div>
        </Style>
    );
}