import React from "react";
import Staging from "../Components/Helper/Staging";
import MainSlider from "../Components/Home/MainSlider";
import ThreeJStester from "../Components/Home/ThreeJStester";
import PageLayout from "./PageLayout";

export default function Home(props) {
  return (
    <PageLayout {...props}>
      <MainSlider/>
      <Staging color={'#e0a0a0'}>قسمت های جذاب</Staging>
      <Staging color={'#a0e0e0'}>درباره من کوتاه با ۳ بعدی افکت</Staging>
      <ThreeJStester/>
      <Staging color={'#e0a0a0'}>پروژه های جذاب</Staging>
      <Staging color={'#a0e0a0'}>نماس با من</Staging>
    </PageLayout>
  );
}