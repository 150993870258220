import React from "react";
import Staging from "../Components/Helper/Staging";
import PageLayout from "./PageLayout";

export default function Aboutus(props) {
  return (
    <PageLayout {...props}>
      <Staging color={'#a0e0e0'}>درباره من کوتاه با ۳ بعدی افکت</Staging>
    </PageLayout>
  );
}