import React from "react";
import styled from "styled-components";

const Style = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding:20vh 0;
`;

export default function Staging({children, color}) {
    return (
      <Style style={{backgroundColor: color}}>
        {children}
      </Style>
  );
}