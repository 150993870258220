import { useEffect } from 'react';
import { useLocation } from 'react-router'

function PageTitles() {
    const history = useLocation();
    useEffect(() => {
        let data = titleData[history.pathname]
        if(data) {
            document.title = data.title;
            let meta = document.querySelector('meta[name=description]');
            if(meta){
                meta.remove();
            }
            let desc = document.createElement("meta");
            desc.name = 'description';
            desc.content = data.desc;
            document.getElementsByTagName('head')[0].appendChild(desc);
        } else {
            document.title = 'گرافیکالی';
        }
        return () => {
        }
    }, [history]);

    return (null);
}

export default PageTitles;



const titleData = {
    '/': {
        title: 'گرافیکالی',
        desc: ''
    },
    
}