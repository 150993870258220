import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Home3D from "../Helper/Home3D";
import useInterval from "../Helper/useInterval";
import CircularMotion from "./CircularMotion";
import CircularSlide from "./CircularSlide";

const Style = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 60vh;
    position: relative;
    .slides {
        flex-grow: 1;
        margin: 2rem;
        border-radius: 1.5rem;
        transition: all 0.3s ease-in-out;
        backdrop-filter: blur(20px);
        display: flex;
        .slide {
            display: flex;
            flex-direction: column;
            justify-content: end;
            padding: 0;
            margin: 0 10vh 10vh 0;
            text-align: right;
            max-width: 20rem;
            color: #fff;
            .title {
                font-size: 2rem;
                font-weight: 800;
                margin-bottom: 0.5rem;
            }
            .text {
                font-size: 1.3rem;
            }
        }
    }
    .slider3d {
        position: absolute;
        top: 0;
        left: 0;
        width: 60vh;
        height: 60vh;
    }
`;

export default function MainSlider() {
    const [slide, setSlide] = useState(0);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
    useEffect(() => {
        // setData2(generateRandomCircle(30, [8, 15]));
        // setData3(generateRandomCircle(20, [8, 15]));
        setData3(generateRandomCircle(5, [2, 8]));
        setData4(generateRandomCircle(20, [2, 10]));
    }, [])
    useInterval(() => {
        if(slide === slides.length - 1){
            setSlide(0);
        } else {
            setSlide(slide + 1);
        }
    }, 3000)
    const makeSlides = () => {
        let value = slides[slide];
        return (
            <div className="slide">
                <div className="title">
                    {value.title}
                </div>
                <div className="text">
                    {value.text}
                </div>
            </div>
        )
    }
    const generateRandomCircle = (num, sizeBound) => {
        let data = [];
        for (let i = 0; i < num; i++) {
            let size = (Math.random() * (sizeBound[1] - sizeBound[0])) + sizeBound[0];
            let locR = (Math.random() * 40) + 10;
            let locDeg = Math.random() * 360;
            let locRad = (locDeg / 180) * Math.PI;
            let locY = Math.sin(locRad) * locR
            let locX = Math.cos(locRad) * locR
            let color =Math.random()
            data.push({
                class: (color > 0.3) ? ((color > 0.65) ? 'green' : 'red') : 'blue',
                top: 50 + locY + '%',
                left: 50 + locX + '%',
                width: size + '%',
                height: size + '%',
                delay: parseInt(Math.random() * 100),
                speed: parseInt((Math.random() * (10 - 5)) + 5),
            })            
        }
        return data;
    }
    return (
      <Style>
        <CircularMotion 
            style={{
                width: '200vh',
                // width: '60vh',
                height: '200vh',
                // height: '60vh',
                top: '-70vh',
                // top: '0vh',
                left: '-100vh',
                // left: '0vh',
                transition: '0.3s all ease-in-out',
                zIndex: 1
            }} 
            data={data4}
            slide={slide}
            mainNum={slides.length}
            offset={164}
        />
        
        <div className="slides" style={{backgroundColor: slides[slide].color, zIndex: 100}}>
            {makeSlides()}
        </div>
        <CircularMotion 
            style={{
                width: '200vh',
                // width: '60vh',
                height: '200vh',
                // height: '60vh',
                top: '-70vh',
                // top: '0vh',
                left: '-100vh',
                // left: '0vh',
                transition: '0.3s all ease-in-out',
                zIndex: 200
            }} 
            data={data3}
            slide={slide}
            mainNum={slides.length}
            offset={164}
        />
        <div className="slider3d" style={{zIndex: 300}}>
            <Home3D/>
        </div>
      </Style>
  );
}

const slides = [
    {
        id: 0,
        title: 'سلام',
        text: 'سلام کوچیک سلام کوچیک سلام کوچیک سلام کوچیک سلام کوچیک سلام کوچیک سلام کوچیک سلام کوچیک',
        color: '#000088aa',
    },
    {
        id: 1,
        title: 'سلام',
        text: 'سلام کوچیک',
        color: '#008800aa',
    },
    {
        id: 2,
        title: 'سلام',
        text: 'سلام کوچیک',
        color: '#880000aa',
    }
]