import React, { Suspense, useEffect, useRef, useState } from 'react'
import styled from "styled-components";
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber'
import { PerspectiveCamera, RoundedBox, useGLTF } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useSpring, a } from '@react-spring/three';
import { AnimationMixer, Clock, LoopPingPong, LoopRepeat } from 'three';

const Style = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: visible;
    
`;

export default function Home3D(props) {
    return (
        <Style>
            <Canvas>
                <Suspense fallback={null}>
                    <CameraAnim/>
                    <ambientLight />
                    <pointLight position={[10, 10, 10]} />
                    <RoundBox2 />
                </Suspense>
            </Canvas>
        </Style>
    )
}
function CameraAnim(props) {
    const camera = useRef()
    useFrame(() => {
        // camera.current.rotation.z += 0.01
    })
    return <PerspectiveCamera ref={camera}  makeDefault fov={45} position={[0,0,5]}/>
}

function Box(props) {
    // This reference will give us direct access to the mesh
    const mesh = useRef()
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
    // Rotate mesh every frame, this is outside of React without overhead
    // useFrame(() => {
    //     mesh.current.rotation.y += 0.02
    //     mesh.current.rotation.z += 0.01
    // })
  
    return (
      <mesh
        {...props}
        ref={mesh}
        scale={active ? 1.5 : 1}
        onClick={(event) => setActive(!active)}
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}>
        <boxGeometry args={[2, 0.2, 2]} />
        <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
      </mesh>
    )
  }


function RoundBox(props) {
    // This reference will give us direct access to the mesh
    const mesh = useRef()
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(true)
    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => {
        mesh.current.rotation.y += 0.02
        mesh.current.rotation.z += 0.02
    })
  
    return (
        <RoundedBox
            {...props}
            ref={mesh}
            scale={active ? 1.5 : 1}
            onClick={(event) => setActive(!active)}
            onPointerOver={(event) => setHover(true)}
            onPointerOut={(event) => setHover(false)}
            args={[1, 1, 1]} 
            radius={0.2} 
            smoothness={4}
        >
            <meshStandardMaterial  color={hovered ? 'hotpink' : 'orange'} />
        </RoundedBox>
        // {/* <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} /> */}
    )
}

function RoundBox2(props) {
    // This reference will give us direct access to the mesh
    const three = useThree()
    const mesh = useRef() 
    const gltf = useLoader(GLTFLoader, 'https://graphically.s3.ir-thr-at1.arvanstorage.com/3d/plane/scene.gltf');
    // Set up state for the hovered and active state
    // const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
    // // Rotate mesh every frame, this is outside of React without overhead
    const clock = new Clock();
    const mixer = new AnimationMixer(gltf.scene)
    if(mesh.current){
        mesh.current.add(gltf.scene)
    }
    const animationAction = mixer.clipAction(gltf.animations[0])
    animationAction.setDuration(1)
    animationAction.play()
    
    
    useFrame(() => {
        // mesh.current.rotation.y += 0.02
        mixer.update(clock.getDelta())
        // console.log(mixer);
    })
    
    const anim = useSpring({
        position: active ? [0,-0.3,2] : [0,-0.3,1]
    })
    return (
        <a.group 
            {...props}
            position={anim.position}
            rotation={[0.4, 0.8, 0.3]}
            onClick={(event) => setActive(!active)}
        >
            <mesh ref={mesh}></mesh>
            {/* <primitive 
                ref={mesh}
                position={[0,0,0]}
                rotation={[0.6, 0.4, 0.8]}
                scale={1.5}
                object={gltf.scene} 
                animations={gltf.animations}
            /> */}
        </a.group>
    )
}