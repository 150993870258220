import React, { useState } from "react";
import Home from "./Pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GoToTop from "./Components/Helper/GoToTop";
// import CommingSoon from "./Pages/CommingSoon";
import PageTitles from "./Components/Helper/PageTitles";
import NotMatch from "./Pages/NotMatch";
import Aboutus from "./Pages/Aboutus";
import { ModalError } from "./Components/ModalError";

function App() {
  const [errors, setErrors] = useState({});
  function updateError({ text, time, type }) {
    setErrors({
      text: text,
      time: time,
      type: type,
    });
  }
  return (
    <BrowserRouter>
      <GoToTop/>
      <PageTitles/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/aboutus" element={<Aboutus/>} />
        <Route path="*" element={<NotMatch/>} />
      </Routes>
      <ModalError errors={errors} updateError={updateError} />
    </BrowserRouter>
  );
}

export default App;
