import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Style = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 30vh;
    height: 30vh;
    position: absolute;
    .base {
        position: absolute;
        opacity: 0.6;
        border-radius: 100rem;
        animation: wiggle 10s infinite linear;
        transform-origin: top;
    }
    .red {
        background-color: #aa0000;
    }
    .blue {
        background-color: #0000aa;
    }
    .green {
        background-color: #aa0000;
    }

    @keyframes wiggle {
        0% { transform: rotate(0deg);}
        100% { transform: rotate(360deg);}
    }
`;

export default function CircularMotion({style, data, slide, mainNum, offset = 0}) {
    const [num, setNum] = useState(0)
    useEffect(() => {
        if((num + 1)%mainNum === slide){
            setNum(num + 1);
        } else {
            setNum(slide)
        }
    }, [slide])
    const makeDivs = () => {
        return data.map((value, index) => {
            return (
                <div 
                    key={index} 
                    className={"base " + value.class} 
                    style={{
                        top: value.top, 
                        left: value.left,
                        width: value.width,
                        height: value.height,
                        animationDelay: value.delay + 'ms',
                        animationDuration: value.speed + 's'
                    }}
                ></div>
            )
        })
    }
    return (
      <Style style={{
            ...style,
            transform: `rotate(${(((num+1)/mainNum)* 360) + offset}deg)`,
        }}>
        {makeDivs()}
      </Style>
  );
}


