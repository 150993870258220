import React, { useRef, useState } from 'react'
import styled from "styled-components";
import { Canvas, useFrame } from '@react-three/fiber'

const Style = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    /* filter: blur(10px); */
`;

export default function ThreeJStester(props) {
    return (
        <Style>
            <Canvas camera={{ fov: 45, near: 0.1, far: 1000, position: [0, 0, 4] }}>
                <ambientLight />
                <pointLight position={[10, 10, 10]} />
                <Box position={[-1.2, 0, -1]} />
                <Box position={[1.2, 0, -1]} />
            </Canvas>
        </Style>
    )
}

function Box(props) {
    // This reference will give us direct access to the mesh
    const mesh = useRef()
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => (mesh.current.rotation.x += 0.02))
  
    return (
      <mesh
        {...props}
        ref={mesh}
        scale={active ? 1.5 : 1}
        onClick={(event) => setActive(!active)}
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}>
        <boxGeometry args={[1, 2, 3]} />
        <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
      </mesh>
    )
  }